// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agents-add-js": () => import("./../../../src/pages/agents/add.js" /* webpackChunkName: "component---src-pages-agents-add-js" */),
  "component---src-pages-agents-edit-js": () => import("./../../../src/pages/agents/edit.js" /* webpackChunkName: "component---src-pages-agents-edit-js" */),
  "component---src-pages-agents-list-js": () => import("./../../../src/pages/agents/list.js" /* webpackChunkName: "component---src-pages-agents-list-js" */),
  "component---src-pages-auth-login-js": () => import("./../../../src/pages/auth/login.js" /* webpackChunkName: "component---src-pages-auth-login-js" */),
  "component---src-pages-building-managers-add-js": () => import("./../../../src/pages/building-managers/add.js" /* webpackChunkName: "component---src-pages-building-managers-add-js" */),
  "component---src-pages-building-managers-edit-js": () => import("./../../../src/pages/building-managers/edit.js" /* webpackChunkName: "component---src-pages-building-managers-edit-js" */),
  "component---src-pages-building-managers-list-js": () => import("./../../../src/pages/building-managers/list.js" /* webpackChunkName: "component---src-pages-building-managers-list-js" */),
  "component---src-pages-buildings-add-js": () => import("./../../../src/pages/buildings/add.js" /* webpackChunkName: "component---src-pages-buildings-add-js" */),
  "component---src-pages-buildings-edit-js": () => import("./../../../src/pages/buildings/edit.js" /* webpackChunkName: "component---src-pages-buildings-edit-js" */),
  "component---src-pages-buildings-list-js": () => import("./../../../src/pages/buildings/list.js" /* webpackChunkName: "component---src-pages-buildings-list-js" */),
  "component---src-pages-commercial-properties-add-js": () => import("./../../../src/pages/commercial-properties/add.js" /* webpackChunkName: "component---src-pages-commercial-properties-add-js" */),
  "component---src-pages-commercial-properties-edit-js": () => import("./../../../src/pages/commercial-properties/edit.js" /* webpackChunkName: "component---src-pages-commercial-properties-edit-js" */),
  "component---src-pages-commercial-properties-list-js": () => import("./../../../src/pages/commercial-properties/list.js" /* webpackChunkName: "component---src-pages-commercial-properties-list-js" */),
  "component---src-pages-currency-edit-js": () => import("./../../../src/pages/currency/edit.js" /* webpackChunkName: "component---src-pages-currency-edit-js" */),
  "component---src-pages-facilities-add-js": () => import("./../../../src/pages/facilities/add.js" /* webpackChunkName: "component---src-pages-facilities-add-js" */),
  "component---src-pages-facilities-edit-js": () => import("./../../../src/pages/facilities/edit.js" /* webpackChunkName: "component---src-pages-facilities-edit-js" */),
  "component---src-pages-facilities-list-js": () => import("./../../../src/pages/facilities/list.js" /* webpackChunkName: "component---src-pages-facilities-list-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-properties-add-js": () => import("./../../../src/pages/properties/add.js" /* webpackChunkName: "component---src-pages-properties-add-js" */),
  "component---src-pages-properties-edit-js": () => import("./../../../src/pages/properties/edit.js" /* webpackChunkName: "component---src-pages-properties-edit-js" */),
  "component---src-pages-properties-list-js": () => import("./../../../src/pages/properties/list.js" /* webpackChunkName: "component---src-pages-properties-list-js" */),
  "component---src-pages-regions-add-js": () => import("./../../../src/pages/regions/add.js" /* webpackChunkName: "component---src-pages-regions-add-js" */),
  "component---src-pages-regions-edit-js": () => import("./../../../src/pages/regions/edit.js" /* webpackChunkName: "component---src-pages-regions-edit-js" */),
  "component---src-pages-regions-list-js": () => import("./../../../src/pages/regions/list.js" /* webpackChunkName: "component---src-pages-regions-list-js" */),
  "component---src-pages-reports-index-js": () => import("./../../../src/pages/reports/index.js" /* webpackChunkName: "component---src-pages-reports-index-js" */),
  "component---src-pages-settings-index-js": () => import("./../../../src/pages/settings/index.js" /* webpackChunkName: "component---src-pages-settings-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

